import React from 'react';

function AboutUsL2() {
    return (
        <div>
            <section>
                <div className="container">
                    <div className="row align-items-center justify-content-between">
                        <div className="col-12 col-lg-6 mb-5 mb-lg-0 order-lg-1">
                            <img src={require('../../assets/images/about/04.png')} alt="Image4" className="img-fluid" />
                        </div>
                        <div className="col-12 col-lg-6">
                            <div className="mb-5">
                                <h2>
                                    <span className="font-w-4 d-block">Your challenges</span> and plan for your business
                                </h2>
                                <p className="lead mb-0">
                                    We use the latest technologies it voluptatem accusantium doloremque laudantium.
                                </p>
                            </div>
                            <div>
                                <div className="mb-3">
                                    <div className="d-flex align-items-start">
                                        <div className="me-3">
                                            {' '}
                                            <span className="list-dot" data-bg-color="#01a479"></span>
                                        </div>
                                        <p className="mb-0">
                                            We use the latest technologies it voluptatem accusantium doloremqu
                                        </p>
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <div className="d-flex align-items-start">
                                        <div className="me-3">
                                            {' '}
                                            <span className="list-dot" data-bg-color="#2f2f41"></span>
                                        </div>
                                        <p className="mb-0">
                                            genius.doctor Landing Page Build With Static Bootstarp Code
                                        </p>
                                    </div>
                                </div>
                                <div>
                                    <div className="d-flex align-items-start">
                                        <div className="me-3">
                                            {' '}
                                            <span className="list-dot" data-bg-color="#01a479"></span>
                                        </div>
                                        <p className="mb-0">All types of businesses need access to development</p>
                                    </div>
                                </div>
                            </div>
                            <a href="/" className="btn btn-outline-primary mt-5">
                                Learn More
                            </a>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default AboutUsL2;
