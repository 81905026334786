const teamMembers = [
    {
        id: 1,
        name: 'Cherie Kerl',
        position: 'Manager',
        imgSrc: 'images/team/01.jpg',
        emaiId: 'themeht23@gmail.com',
        phoneNo: '+91-234-567-8900',
        socialMedia: [
            { icon: 'bi-facebook', link: '/index-2' },
            { icon: 'bi-dribbble', link: '/index-2' },
            { icon: 'bi-twitter', link: '/index-2' },
            { icon: 'bi-linkedin', link: '/index-2' },
        ],
    },
    {
        id: 2,
        name: 'Rashvin Kathiriya',
        position: 'CEO',
        imgSrc: 'images/team/02.jpg',
        emaiId: 'rashvinKathiriya10@gmail.com',
        phoneNo: '+91-234-567-8900',
        socialMedia: [
            { icon: 'bi-facebook', link: '/index-2' },
            { icon: 'bi-dribbble', link: '/index-2' },
            { icon: 'bi-twitter', link: '/index-2' },
            { icon: 'bi-linkedin', link: '/index-2' },
        ],
    },
    {
        id: 3,
        name: 'Loung Willey',
        position: 'Founder',
        imgSrc: 'images/team/03.jpg',
        emaiId: 'themeht23@gmail.com',
        phoneNo: '+91-234-567-8900',
        socialMedia: [
            { icon: 'bi-facebook', link: '/index-2' },
            { icon: 'bi-dribbble', link: '/index-2' },
            { icon: 'bi-twitter', link: '/index-2' },
            { icon: 'bi-linkedin', link: '/index-2' },
        ],
    },
    {
        id: 4,
        name: 'Angel Jessica',
        position: 'Supervisor',
        imgSrc: 'images/team/04.jpg',
        emaiId: 'themeht23@gmail.com',
        phoneNo: '+91-234-567-8900',
        socialMedia: [
            { icon: 'bi-facebook', link: '/index-2' },
            { icon: 'bi-dribbble', link: '/index-2' },
            { icon: 'bi-twitter', link: '/index-2' },
            { icon: 'bi-linkedin', link: '/index-2' },
        ],
    },
];
export default teamMembers;
